import React, { Suspense } from 'react'
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { useSelector } from 'react-redux'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ChangePassword = React.lazy(() => import('./views/pages/changePassword/ChangePassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

const App = () => {
  const token = useSelector((state) => state.token) || localStorage.getItem('token')
  // const user = useSelector((state) => state.userDetails) || JSON.parse(localStorage.getItem('user'))

  return (
    <BrowserRouter basename=".">
      <Suspense fallback={<CSpinner color="primary" />}>
        <Switch>
          <Route
            exact
            path="/login/:type"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/change-password"
            name="Password Change Page"
            render={(props) => <ChangePassword {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route path="/apps/email" name="Email App" render={(props) => <EmailApp {...props} />} />
          <Route path="/" name="Home">
            {token ? <DefaultLayout /> : <Redirect to="/login/coorporate" />}
          </Route>
          {/* <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} /> */}
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
